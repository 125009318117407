<template>
    <!--我的借阅-->
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" @submit.native.prevent @keyup.enter.native="refreshList()">
            <el-form-item label="关键字：">
                <el-input size="small" v-model.trim="srhStr" maxlength="50" placeholder="请输入藏品名称或资源名称"
                          clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 296px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="exhibitionId" label="图片" width="110">
                    <template slot-scope="scope">
                        <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg"
                                  style="width: 100px; height: 100px;">
                            <el-image slot="placeholder" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')"
                                      style="width: 100px; height: 100px"></el-image>
                            <el-image slot="error" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')"
                                      style="width: 100px; height: 100px"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="general_num" width="120" label="藏品编号">
                    <template slot-scope="scope">
                        {{ scope.row.general_num ? scope.row.general_num : scope.row.help_num }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="collection_name" width="120"
                                 label="藏品名称"></el-table-column>
                <el-table-column show-overflow-tooltip prop="resource_name" width="120" label="资源名称"></el-table-column>
                <el-table-column width="120"
                                 prop="name"
                                 label="当前资源">
                    <template slot-scope="scope">
                        <img v-show="scope.row.resource_type == 0"
                             :src="require('@/assets/img/threeIcon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 1"
                             :src="require('@/assets/img/imgIcon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 2"
                             :src="require('@/assets/img/audioIcon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 3"
                             :src="require('@/assets/img/mp4Icon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 4"
                             :src="require('@/assets/img/xlsIcon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 5"
                             :src="require('@/assets/img/word.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 6"
                             :src="require('@/assets/img/zipIcon.png')" class="resourcesIcon">
                        <img v-show="scope.row.resource_type == 7"
                             :src="require('@/assets/img/document.png')" class="resourcesIcon">
                    </template>
                </el-table-column>
                <el-table-column width="120"
                                 prop="column_name"
                                 label="类别" show-overflow-tooltip>
                </el-table-column>
                <el-table-column width="120"
                                 prop="resource_keys"
                                 label="标签" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.resource_keys!=null&& scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{item}};</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="apply_status" width="120" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("apply_status", scope.row.apply_status ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="on_expire" width="120" label="归还状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.apply_status == 2">{{scope.row.on_expire ? '已归还': '未归还'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="end_format_time" width="120" label="开始时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="start_format_time" width="120" label="结束时间" show-overflow-tooltip></el-table-column>
                <el-table-column
                        fixed="right"
                        width="250"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="hasPermission('myBorrowing:list:view')"
                                   @click="view(scope.row)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-show="!scope.row.on_expire && scope.row.apply_status == 2"
                                   @click="returnData(scope.row)">归还
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermission('myBorrowing:list:borrowing')"
                                   @click="borrow(scope.row)"
                                   v-if="scope.row.flag == 1">借阅
                        </el-button>
                        <el-button type="text" size="small" @click="download(scope.row)"
                                   v-if="scope.row.apply_status == 2 && !scope.row.on_expire && scope.row.validityFlag">下载
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--借阅-->
        <BorrowForm ref="borrowForm" @refreshDataList="refreshList"></BorrowForm>
    </div>
</template>

<script>
    import BorrowForm from './BorrowForm' // 借阅弹窗

    export default {
        components: {BorrowForm},
        data() {
            return {
                srhStr: '',
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false,
            }
        },
        created() {
            let srhData = JSON.parse(sessionStorage.getItem('myBorrowOpt'))
            if (srhData != null && srhData) {
                this.srhStr = srhData.srhStr
                this.pageNo = parseInt(srhData.pageNo);
            }
            sessionStorage.removeItem('myBorrowOpt')
        },
        mounted() {
            this.refreshList()
        },
        methods: {
            // 查询
            refreshList(type) {
                if (type == 1) {
                    this.pageNo = 1
                }
                this.loading = true
                this.$axios(this.api.rcPage.homeBorrowingList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'name': this.srhStr,
                    'type': 1
                }, 'get').then(res => {
                    if (res.status) {
                        this.total = parseInt(res.data.total);
                        this.dataList = res.data.records;
                        this.loading = false
                    }
                })
            },
            // 详情
            view(row) {
                let opt = {
                    srhStr: this.srhStr,
                    pageNo: this.pageNo,
                }
                sessionStorage.setItem('myBorrowOpt', JSON.stringify(opt))
                var newRow = row
                newRow.id = newRow.foreign_key_id
                sessionStorage.setItem('dizhi', '/dyyg/home/myBorrow')
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: newRow
                });
            },
            // 归还
            returnData(row) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定归还资源？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '归还后，借阅的资源权限将被收回')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios(this.api.rcPage.giveBack, [{
                        id: row.id,
                        onExpire: 1,
                    }], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('归还成功')
                        } else {
                            this.$message.error('归还失败')
                        }
                        this.refreshList()
                    })
                });
            },
            // 借阅
            borrow(item) {
                let data = [item] || this.dataListSelections
                this.$refs.borrowForm.init(data)
            },
            // 下载
            download(data) {
                let id = data.foreign_key_id
                if (data.digitalCollectionId != null) {
                    id += "," + data.digitalCollectionId
                }
                this.$message.info("资源开始下载请勿重复点击")
                this.$axios(this.api.rcPage.borrowingDownloadFile, {'ids': id}, 'get', 'blob').then(res => {

                    if (res.status == 201) {
                        this.$message.error("当前资源未存在文件或文件损坏！")
                        return
                    }
                    let blob = new Blob([res.data], {type: 'application/zip'})
                    let filename = '文件';
                    let link = document.createElement('a')
                    link.download = decodeURI(filename)
                    link.href = window.URL.createObjectURL(blob)
                    link.click()


                })
            },
            reset() {
                this.srhStr = ''
                this.refreshList(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>
    .resourcesIcon {
        width: 2rem;
        margin: 0 0.5rem;
    }
</style>
